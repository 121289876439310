<template>
  <div class="resize">
    <!--    <p>-->
    <div class="l-col">
      <label class="standart-text">{{ $t('disk.label') }}</label>
    </div>
    <!--        :label="$t('disk.label')"-->
    <!--    </p>-->
    <!--    <div class="password">-->
    <div class="l-col">
      <base-input v-model="root" class="form__field--input" :readonly="true">
        <template #icon>
          <plain-button
            v-tooltip="{
              content:
                icon === 'copy-gray'
                  ? 'скопировать пароль в буфер обмена'
                  : 'пароль скопирован в буфер обмена',
              placement: 'bottom-end',
              container: false,
            }"
            :icon="icon"
            size="medium"
            color="dim"
            tabindex="-1"
            @click="copyToClipboard()"
          /> </template
      ></base-input>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Clipboard from 'v-clipboard';
Vue.use(Clipboard);
import setFocus from '@/mixins/setFocus';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import passfather from 'passfather';
export default {
  name: 'ChangePassword',
  components: { BaseInput },
  mixins: [setFocus],
  props: {
    name: {
      type: String,
      default: '',
    },
    instance: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      root: '',
      icon: 'copy-gray',
    };
  },
  computed: {},
  mounted() {
    this.generatePass();
  },
  methods: {
    generatePass() {
      const pass = passfather({
        numbers: false,
        uppercase: false,
        lowercase: false,
        symbols: false, // Disable symbols
        ranges: [
          [
            [65, 72],
            [74, 78],
            [80, 90],
            [97, 107],
            [109, 122],
          ],
          [[50, 57]],
        ],
        length: 16,
      });
      this.root = pass;
      // console.log(pass);
      this.$emit('change', this.root);
      return pass;
    },
    copyToClipboard() {
      this.$clipboard(this.root);
      this.icon = 'accept';
    },
    showSuccess(msg) {
      this.$modals.open({
        name: 'Success',
        text: msg,
        footer: {
          centered: true,
          cancel: {
            props: { title: this.$t('ok') },
            on: { click: () => this.$modals.close() },
          },
        },
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "disk": {
      "label": "Новый пароль для сервера:",
      "description" : "Текстовое описание"
    },
    "sure": {
      "confirm": "Подтвердить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.password {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;

  &__field {
    margin-left: 0.25rem;
    min-height 3rem;
    //margin-top: 1.5rem;
  }
}
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
