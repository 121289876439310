var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resize"},[_c('div',{staticClass:"l-col"},[_c('label',{staticClass:"standart-text"},[_vm._v(_vm._s(_vm.$t('disk.label')))])]),_c('div',{staticClass:"l-col"},[_c('base-input',{staticClass:"form__field--input",attrs:{"readonly":true},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content:
              _vm.icon === 'copy-gray'
                ? 'скопировать пароль в буфер обмена'
                : 'пароль скопирован в буфер обмена',
            placement: 'bottom-end',
            container: false,
          }),expression:"{\n            content:\n              icon === 'copy-gray'\n                ? 'скопировать пароль в буфер обмена'\n                : 'пароль скопирован в буфер обмена',\n            placement: 'bottom-end',\n            container: false,\n          }"}],attrs:{"icon":_vm.icon,"size":"medium","color":"dim","tabindex":"-1"},on:{"click":function($event){return _vm.copyToClipboard()}}})]},proxy:true}]),model:{value:(_vm.root),callback:function ($$v) {_vm.root=$$v},expression:"root"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }