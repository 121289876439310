<template>
  <div class="resize">
    <!--    <label class="resize-row standart-text">{{ $t('server.name') }}</label>-->
    <div class="l-col">
      <base-input
        v-model="newNameServer"
        :label="$t('server.name')"
        class="form__field--input"
        use-reactive-validation
        @input="onChange()"
      >
      </base-input>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import passfather from 'passfather';
export default {
  name: 'RenameServer',
  components: { BaseInput },
  mixins: [setFocus],
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newNameServer: '',
    };
  },
  computed: {},
  mounted() {
    this.newNameServer = this.name;
  },
  methods: {
    onChange() {
      this.$emit(
        'change',
        this.newNameServer.length > 63 ? this.newNameServer.substr(0, 63) : this.newNameServer
      );
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "server": {
      "name": "Имя сервера:",
      "description" : "Текстовое описание"
    },
    "sure": {
      "confirm": "Хорошо"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;
  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .75rem .75rem;
  }
}
</style>
