var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resize"},[_c('div',{staticClass:"l-col"},[_c('label',{staticClass:"typo__label standart-text"},[_c('p',{staticClass:"tabulation-base",domProps:{"innerHTML":_vm._s(_vm.$t('server.label', { name: _vm.name, id: _vm.instance.id }))}})])]),(_vm.isDeleteDisk && _vm.isDeleteDisk.length > 0)?_c('div',{staticClass:"l-col"},[_c('p',[_c('label',{staticClass:"typo__label standart-text underline"},[_vm._v(_vm._s(_vm.$t('server.encore')))])]),_c('div',[_c('label',{staticClass:"typo__label standart-text"},[_c('p',{staticClass:"tabulation",domProps:{"innerHTML":_vm._s(
            _vm.$t('server.disk', {
              name: _vm.isDeleteDisk[0].name,
              size: _vm.isDeleteDisk[0].size,
              id: _vm.isDeleteDisk[0].id,
            })
          )}})])]),(_vm.fixed.length)?_c('div',_vm._l((_vm.fixed),function(ip){return _c('div',{key:ip.id},[_c('label',{staticClass:"typo__label standart-text"},[_c('p',{staticClass:"tabulation",domProps:{"innerHTML":_vm._s(_vm.$t('server.fixed', { ip: ip.ip, name: ip.name }))}})])])}),0):_vm._e(),(_vm.sharedIp.length)?_c('div',_vm._l((_vm.sharedIp),function(ip){return _c('div',{key:ip.ip},[_c('label',{staticClass:"typo__label standart-text"},[_c('p',{staticClass:"tabulation",domProps:{"innerHTML":_vm._s(
              _vm.$t('server.publicPort', {
                ip: ip.ip,
                name: _vm.publicSubnetName(ip.name),
              })
            )}})])])}),0):_vm._e()]):_vm._e(),(_vm.undelete)?_c('div',{staticClass:"l-col"},[_c('p',[_c('label',{staticClass:"typo__label standart-text underline"},[_vm._v(_vm._s(_vm.$t('server.undelete')))])]),(_vm.isUndeleteDisk)?_c('div',_vm._l((_vm.isUndeleteDisk),function(disk){return _c('div',{key:disk.name},[_c('label',{staticClass:"typo__label standart-text"},[_c('p',{staticClass:"tabulation",domProps:{"innerHTML":_vm._s(
              _vm.$t('server.disk', {
                name: disk.name,
                size: disk.size,
                id: disk.id,
              })
            )}})])])}),0):_vm._e(),(_vm.shared)?_c('div',_vm._l((_vm.shared),function(net){return _c('div',{key:net.name},[_c('label',{staticClass:"typo__label standart-text"},[_c('p',{staticClass:"tabulation",domProps:{"innerHTML":_vm._s(_vm.$t('server.publicNet', { name: _vm.subnetName(net) }))}})])])}),0):_vm._e(),(_vm.float.length)?_c('div',_vm._l((_vm.float),function(ip){return _c('div',{key:ip.ip},[_c('label',{staticClass:"typo__label standart-text"},[_c('p',{staticClass:"tabulation",domProps:{"innerHTML":_vm._s(_vm.$t('server.float', { ip: ip.ip }))}})])])}),0):_vm._e()]):_vm._e(),(_vm.isUndeleteDisk.length > 0 || _vm.shared.length > 0 || _vm.float.length > 0)?_c('div',{staticClass:"l-col"},[_c('label',{staticClass:"typo__label standart-text"},[_vm._v(_vm._s(_vm.$t('server.resource')))]),_c('br'),_c('label',{staticClass:"typo__label standart-text"},[_vm._v(_vm._s(_vm.$t('server.resourceDelete')))])]):_vm._e(),(_vm.flavorCost > 0)?_c('div',{staticClass:"l-col medium-text"},[_c('label',{staticClass:"typo__label standart-text"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.summaryCost)}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }